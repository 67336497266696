import React from "react"
import styled, { css } from "styled-components"
import { rem } from "../../utils/styling"

import CanidLogo from "../../images/canid.svg"
import IshiLogo from "../../images/ishi.svg"
import EsportLogo from "../../images/esport.svg"
import AccurKardiaLogo from "../../images/accur-white.svg"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "../../utils/deviceType"

const Container = styled.div`
  padding: ${rem(10)}rem 0;
  position: relative;
  background-color: #fff;
  width: 100vw;
  padding: ${rem(60)}rem 0 0;
  @media screen and (min-width: 1100px) {
    padding: ${rem(140)}rem 0 0;
  }
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #03162c;
  backdrop-filter: blur(20px);
  margin: 0;
  padding: ${rem(50)}rem ${rem(30)}rem;
  min-height: ${rem(500)}rem;

  @media screen and (min-width: 1100px) {
    margin: ${rem(50)}rem;
    padding: ${rem(50)}rem ${rem(150)}rem;
    border-radius: 12px;
  }
`

const BannerTitle = styled.h1`
  font-size: ${rem(28)}rem;
  line-height: ${rem(40)}rem;
  letter-spacing: -1px;
  color: #ffffff;
  margin: 0 0 ${rem(20)}rem;
  font-weight: 800;
  text-align: center;

  span {
    font-style: normal;
    color: #fcb240;
    margin-left: ${rem(5)}rem;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(40)}rem;
  }
`

const Bannertext = styled.p`
  font-size: ${rem(16)}rem;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(18)}rem;
    width: 70%;
  }
`

const BannerLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: ${rem(60)}rem 0 0;
  width: 100%;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    width: 90%;
  }
`

const BannerLogo = styled.img`
  fill: #fff;
  padding: ${rem(10)}rem 0;
  height: auto;
  width: ${rem(200)}rem;
  @media screen and (min-width: 1100px) {
    padding: 0;
  }
  ${props =>
    props.ishi &&
    css`
      width: ${rem(70)}rem;
    `}
  ${props =>
    props.esports &&
    css`
      width: ${rem(250)}rem;
    `}
`

const ApprenticeshipBanner = () => {
  const deviceType = useDeviceType()
  return (
    <Container>
      <BannerContent>
        <BannerTitle>
          Apprentice at <br /> <span>Curated Startups</span>
        </BannerTitle>
        <Bannertext>
          Providing aspiring developers from across the world a window of
          opportunity to intern with top-tier, venture funded startups while
          being mentored by the best in the industry
        </Bannertext>
        <BannerLogoContainer>
          <BannerLogo src={CanidLogo} />
          <BannerLogo src={EsportLogo} esports />
          <BannerLogo src={AccurKardiaLogo} />
          <BannerLogo src={IshiLogo} ishi />
        </BannerLogoContainer>
      </BannerContent>
    </Container>
  )
}

export default ApprenticeshipBanner
