import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "../../utils/styling"
import { mentorData } from "../../data/apprenticeData"
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai"
import { MobileOnlyCarousel } from "src/components/Carousel"

const Container = styled.div`
  padding: ${rem(10)}rem 0;
  position: relative;
  background-color: #fff;
  width: 100vw;
  background: #ffffff;
  margin-bottom: ${rem(100)}rem;
`

const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${rem(60)}rem 0 ${rem(20)}rem;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    margin: 0;
  }

  .slick-slide {
    padding: ${rem(20)}rem;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: #000000;
  text-align: center;
  align-self: center;
  font-family: Inter;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

const Card = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 8px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: ${rem(30)}rem ${rem(20)}rem;
  margin: ${rem(30)}rem 0;

  &:hover {
    background: #fff3e1;
    box-shadow: 10px 10px 0px #ffdfaf;
    border: none;
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 1100px) {
    padding: ${rem(30)}rem ${rem(20)}rem;
    width: ${rem(300)}rem;
    margin: 0 ${rem(20)}rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const CardImage = styled.img`
  width: 130px;
  height: 130px;
  margin-bottom: ${rem(10)}rem;
`

const CardName = styled.p`
  color: #000000;
  font-size: ${rem(16)}rem;
  text-align: center;
  margin-bottom: ${rem(5)}rem;
`

const Cardtag = styled.p`
  color: #000000;
  font-weight: 800;
  font-size: ${rem(12)}rem;
  text-align: center;
  margin-bottom: ${rem(5)}rem;
  text-transform: uppercase;
`

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${rem(20)}rem;

  .profileIcon {
    font-size: ${rem(25)}rem;
    &:first-child {
      margin-right: ${rem(5)}rem;
    }
  }
`

const CardText = styled.p`
  color: #000000;
  font-size: ${rem(14)}rem;
  text-align: center;
  line-height: 22px;
  opacity: 0.5;
`

const Mentor = () => {
  return (
    <Container>
      <SectionTitle>Your Mentors</SectionTitle>
      <CardRow>
        <MobileOnlyCarousel>
          {mentorData.map((item, idx) => (
            <Card key={idx}>
              <CardImage src={item.image} alt={item.name} />
              <CardName>{item.name}</CardName>
              <Cardtag>{item.location}</Cardtag>
              {/*<IconRow>*/}
              {/*  <AiFillLinkedin className="profileIcon" />*/}
              {/*  <AiFillGithub className="profileIcon" />*/}
              {/*</IconRow>*/}
              <CardText>{item.text}</CardText>
            </Card>
          ))}
        </MobileOnlyCarousel>
      </CardRow>
    </Container>
  )
}

export default Mentor
